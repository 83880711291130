import wrapperCss from 'bundle-text:./wrapper.css'; // @ts-expect-error: not supported
import { sanitizeLocale } from 'locale/sanitize';

import {
  attachElmPort,
  ElmError,
  fetchModule,
  generateRelay,
  getEnvironment,
  getIframeUrl,
  getTranslations,
  LogMessage,
  popupCenter,
  sendLog,
  tryParse,
  waitForPort,
} from './helpers';
import versions from './tmp/versions';
import { Module, SentryEvent } from './types';

const variants = new Map<string, string>(versions.variants as any);
const modules = new Map<string, Module>();

function getModule(variantName: string, requestedLocale: string): Module {
  const mod = variants.get(variantName);
  let locale = sanitizeLocale(requestedLocale);
  if (!mod) {
    throw new Error('Invalid variant.');
  }

  if (!versions.locales.includes(locale)) {
    console.warn(
      `Locale ${locale} not found for variant ${variantName}, defaulting to "en-us".`
    );

    locale = 'en-us';
  }
  const script = versions.example
    .replace('__NAME__', variantName)
    .replace('__LOCALE__', locale);

  if (!modules.has(script)) {
    modules.set(script, {
      script,
      name: mod,
      translations: versions.translations,
    });
  }

  return modules.get(script);
}

const mandatoryParams = [
  'relay',
  'client_id',
  'locale',
  'redirect_uri',
  'scope',
  'sl',
  'response_type',
  'dt',
];

document
  .querySelectorAll('meta[name="susi-sentry-preload"]')
  .forEach((meta: HTMLMetaElement) => {
    const forceStage = meta.dataset.stage === 'true';
    const locale = meta.dataset.locale;
    const mod = getModule(meta.content, locale);

    mod && fetchModule(mod, forceStage);
  });

class SentryWrapper extends HTMLElement {
  // ROOT
  private renderRoot: ShadowRoot;
  private isConstructed = false;

  // Temp state to detect changes
  private _authparams: Record<string, string> | null = null;
  private _config: Record<string, string> | null = null;
  private _variant: string | null = null;
  private _stage: boolean = false;
  private _popup: boolean = false;

  private module!: Module;

  private _variantPromise: Promise<void> | null = null;
  private logMessage: (message: LogMessage) => void;

  private requiredParams = ['authParams', 'config', 'variant'];
  constructor() {
    super();

    try {
      this.renderRoot = this.attachShadow({ mode: 'open' });
      this.popup = this.getAttribute('popup');
      this.stage = this.getAttribute('stage');
      this.variant = this.getAttribute('variant');
    } catch (e) {
      console.warn('SUSI Light - Missing parameters on init.', e);
    } // Treat initial values as optional
  }

  connectedCallback() {
    this.isConstructed = true;

    this.attemptRenderModule();
  }

  set variant(value: string) {
    if (value) {
      this._variant = value;
    }

    this.attemptRenderModule();
  }

  get variant() {
    return this._variant ?? '';
  }

  get locale() {
    return this.authParams.locale;
  }

  get popup() {
    return this._popup;
  }

  set popup(val: boolean | string) {
    this._popup = typeof val === 'string' ? val === 'true' : Boolean(val);

    this.attemptRenderModule();
  }

  get stage() {
    return this._stage;
  }

  set stage(val: boolean | string) {
    this._stage = typeof val === 'string' ? val === 'true' : Boolean(val);

    this.attemptRenderModule();
  }

  get darkMode() {
    return this.authParams?.dt;
  }

  set authParams(value: Record<string, string>) {
    this._authparams = value;
    this.attemptRenderModule();
  }

  get authParams(): Record<string, string> {
    return this._authparams;
  }

  set config(value: Record<string, string>) {
    this._config = value;

    this.attemptRenderModule();
  }

  get config(): Record<string, string> {
    return this._config;
  }

  get systemParams() {
    return {
      mode: Boolean(this.popup) ? 'popup' : 'redirect', // eslint-disable-line
    };
  }

  get appState() {
    if (!this.authParams) {
      return {};
    }

    const relay = this.authParams.relay ?? generateRelay();
    const sl_stage = !!this.stage;
    const params = { ...this.authParams, sl: window.origin, sl_stage, relay };

    const isMandatory = key => mandatoryParams.includes(key);

    const queryState = Object.fromEntries(
      Object.entries(params).filter(([key, val]) => val && isMandatory(key))
    );

    const authParams = Object.entries(params).map(([k, v]) => [
      k,
      v.toString(),
    ]);

    return {
      queryState,
      authParams,
      systemParams: this.systemParams,
      config: this.config,
    };
  }

  async initializeApplication(root: HTMLElement, port: MessagePort) {
    const env = getEnvironment(window.location.href, this.stage);
    const translations = getTranslations(
      env,
      this.module,
      sanitizeLocale(this.locale)
    );

    const app = window.Elm[this.module.name].Main.init({
      node: root,
      flags: {
        ...this.appState,
        translations,
      },
    });

    if (app.ports) {
      attachElmPort<string>(app.ports, 'onOpenPopup', url => {
        popupCenter({ url, title: 'SUSI Light Login', w: '459', h: '768' });
      });

      attachElmPort<SentryEvent>(app.ports, 'onSentryEvent', event => {
        this.dispatchEvent(new CustomEvent(event.name, { detail: event.data }));

        if (event.name === 'on-analytics') {
          port.postMessage(event);
          this.logMessage({
            name: 'app-analytics',
            message: event.data,
          });
        }
      });

      attachElmPort<LogMessage>(app.ports, 'onLogMessage', event => {
        if (event.name === 'app-error') {
          const error = new ElmError(event.message);
          this.dispatchError(error);
        } else {
          this.logMessage(event);
        }
      });
    }

    return app;
  }

  static observedAttributes = [
    'authParams',
    'config',
    'stage',
    'variant',
    'stage',
    'popup',
  ];

  createShadow() {
    return this.attachShadow({ mode: 'closed' });
  }

  renderModule() {
    this.module = getModule(this.variant, this.locale);
    this._variantPromise = fetchModule(this.module!, this.stage);

    if (this.isConstructed) {
      this.render();
    }
  }

  attemptRenderModule() {
    const missingParams = this.requiredParams.filter(key => !this[key]);
    if (missingParams.length > 0) {
      console.warn(
        `All required parameters not yet supplied: ${missingParams.join(', ')}`
      );
      return;
    }

    const { queryState } = this.appState;
    const env = getEnvironment(window.location.href, this.stage);

    this.logMessage = sendLog(
      env,
      queryState.client_id,
      queryState.relay,
      this.variant
    );

    this.renderModule();
  }

  async render() {
    const env = getEnvironment(window.location.href, this.stage);
    const iframeUrl = getIframeUrl(env);
    const portPromise = waitForPort(iframeUrl);
    const { client_id, relay } = this.appState.queryState;
    const isValidOrigin = this.authParams.st_valid_origin;

    function makeIframeUrl() {
      const url = new URL('/sentry/iframe/index.html', iframeUrl);
      url.searchParams.append('client_id', client_id);
      url.searchParams.append('relay', relay);
      if (isValidOrigin) {
        url.searchParams.append('st_valid_origin', 'true');
      }
      url.searchParams.append('asserted_origin', window.location.origin);

      return url.toString();
    }

    const colorTheme = this.darkMode ? 'dark-mode' : 'light-mode';

    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = `
        <style>
          ${wrapperCss}
        </style>
        <section id="sentry-container" class=${colorTheme}>
          <section id="sentry"></sentry>
        </section>
        <iframe
          aria-hidden="true"
          id="sentry-iframe"
          height="1px" 
          width="1px" 
          src="${makeIframeUrl()}" />
    `;
    } else throw new Error('shadowRoot not available');

    try {
      const [port] = await Promise.all([portPromise, this._variantPromise]);
      const app = await this.initializeApplication(
        this.renderRoot.getElementById('sentry')!,
        port
      );

      const logError = this.logMessage;

      port.onmessage = function (e) {
        try {
          const event = tryParse<SentryEvent>(e.data);
          const subEvent = tryParse<SentryEvent>(event.data);
          if (app.ports && event.name === 'query-state') {
            app.ports.onPopupMessage.send(subEvent);
          } else {
            console.log(e);
          }
        } catch (e) {
          logError({
            name: 'app-critical',
            message: { error: { message: e.toString() } },
          });
        }
      };
    } catch (e) {
      this.dispatchError(e);
    }
  }

  dispatchError(error: Error) {
    const errorEvent = {
      name: 'unrecoverable',
      error: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
    };

    this.dispatchEvent(new CustomEvent('on-error', { detail: errorEvent }));
    this.logMessage({
      name: 'app-error',
      message: errorEvent,
    });
  }
}

// TODO: Handle tag name differential
window.customElements.define('susi-sentry-light', SentryWrapper);
